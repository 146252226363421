/*
    FOOTER
*/

.prefooter {
    height: 0;
    padding-bottom: 50%;
    background: url(/content/footer-compu.png) center bottom / 100% auto no-repeat;

    @include breakpoint(large) {
        padding: 0;
        height: 30rem;
        background-size: auto;
    }
}

footer {
    background: $dark-gray;
    color: white;
    font-size: .875rem;
    padding: 2rem 0;

    h4 {
        color: white;
        position: relative;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 1rem;

        &:before {
            content: '';
            width: 100%;
            height: 1px;
            background: white;
            position: absolute;
            left: 0;
            top: 50%;
        }

        span {
            position: relative;
            z-index: 1;
            background: $dark-gray;
            padding: 0 .5rem 0 0;
        }
    }

    ul.contact-details {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            margin-bottom: .5rem;

            a {
                color: white;
            }

            span {

                display: inline-block;
                white-space: nowrap;
                vertical-align: top;

                &:first-child {
                    width: 100px;
                }

                img {
                    width: 1.5rem;
                }
            }
        }
    }

    .footer-logo {
        height: 100%;
        display: flex;
        vertical-align: middle;
        padding: 2rem 0;
        justify-content: center;

        @include breakpoint(medium) {
            justify-content: flex-end;
        }
    }
}

.bottom-bar {
    background: $primary-color;
    padding: .75rem;

    p {
        font-size: .75rem;
        color: white;
        margin: 0;
    }
}


