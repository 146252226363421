/*
    GLOBAL
*/

body {
    //padding-top: 4.875rem;        // Space for fixed header
    //padding-bottom: 4.25rem;       // Space for fixed footer
    //min-height: 100%;
    background-image: linear-gradient(15deg, rgb(200,200,200), rgb(235,235,235) 20%, rgb(255,255,255));
}

section {
    padding: 1rem 0;
    overflow-x: hidden;        // Prevents absolute positioned miscelaneous to generate horizontal scroll
    
    @include breakpoint(medium) {
        padding: 2rem 0;
    }


    @include breakpoint(medium) {
        padding: 3.5rem 0;
    }

    @include breakpoint(large) {
        padding: 5rem 0;
    }
}

h2.section-title {
    margin-bottom: 2rem;
    
    @include breakpoint(medium) {
        margin-bottom: 3rem;
    }

    @include breakpoint(large) {
        margin-bottom: 4rem;
    }

    &.blue-bgr {
        padding: 1rem;
        background-color: $primary-color;
        color: white;
    }

    &.gray-bgr {
        padding: 1rem;
        background-color: $medium-gray;
        color: white;
    }

}

a {
}

.button {
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    letter-spacing: .2px;
}

.hover {
}

.mb2 { margin-bottom: 2rem; }
.mb4 { margin-bottom: 4rem; }

.pt1 { padding-top: 1rem; }

.fs20 {
    font-size: 1.25rem;
}



@keyframes loader {
    0%   { transform: rotate(0); }
    100% { transform: rotate(360deg); }
}

body.loading {
    overflow: hidden;
    position: relative;
}

body .preloader-wrapper {
    transition: all .3s .3s ease;

    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
    position: absolute;
    pointer-events: none;

    opacity: 0;
}

body .preloader-wrapper .preloader{
    transition: all .3s ease;

    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    background: url(/img/lucia-siniga-iso.svg) center center no-repeat;
    animation: loader 2s infinite linear;
    z-index: 11;

    opacity: 0;
}

body.loading .preloader-wrapper {
    opacity: 1;

}
body.loading .preloader-wrapper .preloader {
    opacity: 1;
}
