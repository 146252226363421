/*
    SLIDER
*/

#main-slider .grid-container {
    @include breakpoint(medium down) {
        padding: 0;
    }
}

.swiper-container {
    width: 100%;
    height: rem-calc(260);
    margin: 0 auto;

    @include breakpoint(medium) {
        height: rem-calc(360);
    }

    @include breakpoint(large) {
        height: rem-calc(460);
    }

    .swiper-slide {
        background: center center / cover no-repeat;
        display: flex;
        align-items: flex-end;

        .slide-description {

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, rgba(0,133,183,0), rgba(0,68,94,1));
                mix-blend-mode: multiply;
            }

            position: relative;
            color: white;
            width: 100%;
            padding: 2rem 0 .5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;


            @include breakpoint(medium) {
                padding: 2rem 0 1rem;
            }

            @include breakpoint(large) {
                padding: 3rem 0 2rem 0;
                flex-direction: row;
                align-items: center;
            }

            h2 {
                font-size: 1.75rem;
                display: inline-block;
                margin: 0 1.25rem;
                position: relative;

                @include breakpoint(medium) {
                    font-size: 2.5rem;
                }

                @include breakpoint(large) {
                    font-size: 3rem;
                    margin: 0 .5rem;
                }
            }

            p {
                display: inline-block;
                margin: 0 1.25rem;
                position: relative;

                @include breakpoint(large) {
                    margin: 0 .5rem;
                }
            }
        }

        &#slide1 {
            background-image: url('/content/banner_eficacia.jpg');
            
            @include breakpoint(large) {
                p {
                    max-width: 400px       
                }
            }
        }

        &#slide2 {
            background-image: url('/content/banner_local.jpg');
            
            @include breakpoint(large) {
                p {
                    max-width: 230px       
                }
            }
        }

        &#slide3 {
            background-image: url('/content/banner_gestion.jpg');
            
            @include breakpoint(large) {
                p {
                    max-width: 350px       
                }
            }
        }
    }
}


.slider-button-prev,
.slider-button-next {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -1rem;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    @include breakpoint(medium) {
        width: 2rem;
        height: 2rem;
        margin-top: -1.5rem;
    }
    
    @include breakpoint(large) {
        width: 2.5rem;
        height: 2.5rem;
        margin-top: -2rem;
    }

    &.swiper-button-disabled {
        opacity: .4;
    }
}

.slider-button-prev {
    background: url('/img/slider-arrow-left.svg') center center / cover no-repeat;
    left: 1rem;
}

.slider-button-next {
    background: url('/img/slider-arrow-right.svg') center center / cover no-repeat;
    right: 1rem;
}
