/*
    HOME
*/


// Homepage

section#main-slider {
    padding: 0;
}


// PRODUCTS

section#prodcuts {
    h2 {
        font-size: 2rem;
    }
}

.products-wrapper,
.products-imgs-wrapper {
    padding: 0 1rem;

    .product-item,
    .product-img {
        padding: 0 1.5rem;
    }
}

@include breakpoint(medium) {
    .products-wrapper,
    .products-imgs-wrapper {
        padding: 0 4rem;
    }
}



.product-item {
    margin-bottom: 1.5rem;

    @include breakpoint(medium) {
        margin-bottom: 3rem;
    }

    .product-icon {
        text-align: center;
        padding: .5rem;
        margin-bottom: .5rem;

        @include breakpoint(small only) {
            img {
                width: 70px;
            }
        }
    }

    .product-title {
        border-bottom: 1px solid $primary-color;
        margin-bottom: 1.5rem;

        h3 {
            text-transform: uppercase;
            text-align: center;
            color: $primary-color;
            font-weight: 500;
            margin: 0;
            padding: 0 0 .75rem;
        }
    }

    .product-description {
        font-size: .875rem;
    }
}

.products-imgs-wrapper {

    img {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: 1px solid $primary-color;
        border-bottom: 1px solid $primary-color;
    }
}




// MODULES

.modules-wrapper {
    /*
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url("/img/modulos_tarjeta.jpg") center center / cover;
    }
    */

    .module-item,
    .module-item-inner {

    }

    .grid-x {

        .cell:first-child .module-item-inner {
            background: url("/img/modulos_tarjeta.jpg") left center;
        }

        .cell:nth-child(2n) .module-item-inner {
            background: url("/img/modulos_tarjeta.jpg") center center;
        }

        .cell:last-child .module-item-inner {
            background: url("/img/modulos_tarjeta.jpg") right center;
        }

    }
}

@include breakpoint(xlarge) {
    .modules-wrapper {
        padding: 0 4rem;
        .module-item {
            padding: 0 1.5rem;
        }
    }

    .modules-imgs-wrapper {
        padding: 0 5.5rem;
    }
}

.module-item {
    margin-bottom: 1.5rem;

    @include breakpoint(medium) {
        height: 100%;
    }
    * {
        color: white;
    }

    .module-item-inner {
        padding: 2rem 1rem 1rem;
        text-align: center;

        @include breakpoint(medium) {
            padding: 2.5rem 1.25rem 1.5rem;
            height: 100%;
            text-align: left;

        }

        @include breakpoint(xlarge) {
            padding: 3.5rem 1.25rem 2.5rem;
        }
    }

    h3 {
        text-transform: uppercase;
        text-align: center;
        color: white;
        font-weight: 500;
        margin: 0;
        padding: 0 0 .75rem;
    }

    .module-title {
        border-bottom: 1px solid white;
        margin-bottom: 1.5rem;
    }

    .module-description {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 1rem;
                text-transform: uppercase;

                @include breakpoint(medium only) {

                    font-size: .875rem;

                }
            }
        }
    }
}


// CLIENTS

section#clients {

}

.clients-wrapper {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;

    .clients-marquee {
        display: inline-block;
        white-space: nowrap;

        .client {
            display: inline-block;
            vertical-align: top;
            width: 100px;
            margin: 0 1rem;
        }
    }
}


// SERVICES

.services-inner {


    ul {
        list-style: none;
        margin: 0;
        padding: 0;


        @include breakpoint(medium) {
            display: inline-block;
        }

        li {
            position: relative;
            font-size: 1rem;
            text-transform: uppercase;
            padding-left: 1rem;
            color: $primary-color;
            font-weight: 500;
            padding-bottom: 1.5rem;

            @include breakpoint(medium) {
                font-size: 1.5rem;
            }

            &:before {
                content: '';
                width: 8px;
                height: 8px;
                position: absolute;
                left: 0;
                top: 6px;
                background: url(/img/services-bullet.svg);

                @include breakpoint(medium) {
                    top: 14px;
                }
            }

        }
    }
}

.synced-with {    // strokes
    ul {

        li {

            &:first-child {
                &:after {
                    content: '';
                    position: absolute;
                    background: none;
                    width: 1px;
                    height: 50%;
                    top: 50%;
                    left: 4px;
                    border-left: 1px solid $primary-color;
                }
            }

            &:before {
                content: '';
                width: 8px;
                height: 8px;
                position: absolute;
                left: 0;
                top: 10px;
                background: url(/img/services-bullet.svg);

                @inlcude breakpoint(medium) {
                    top: 14px;
                }
            }

            &.synced-logo {
                margin: 0;
                text-align: center;

                &:before {
                    content: '';
                    position: absolute;
                    background: none;
                    width: 1px;
                    height: 100%;
                    top: 0;
                    left: 4px;
                    border-left: 1px solid $primary-color;
                }

                &:after {
                    content: '';
                    position: absolute;
                    background: none;
                    width: 50px;
                    height: 1px;
                    top: 40%;
                    left: 4px;
                    border-top: 1px solid $primary-color;
                }

                img {
                    height: 65px;
                    width: auto;
                }

                &:last-child:before {
                    height: 40%;
                }
            }
        }
    }
}


// WhatsApp widget

.whatsapp-widget {
    position: fixed;
    z-index: 100;
    right: 1rem;
    bottom: 1rem;

    a {
        display: block;
        width: 4rem;
        height: 4rem;
        padding: .75rem;
        border-radius: 50%;
        background-color: #00E676;
        color: white;
        transition: all .15s ease-out;

        img {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    &:hover {

        a {
            transform: scale(1.2);
        }
    }
}
