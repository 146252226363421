/*
    HEADER
*/

header {
    
    
    .top-bar {
        padding: 1.5rem 0 1.5rem 0;
        align-items: flex-end;
        transition: all .3s ease-out;
        background: white;

        @include breakpoint(large) {
            z-index: 10;
        }
    }

    

    /*
    @include breakpoint(large) {
        padding-bottom: 1rem;
    }
    */

/*
    a {
        //margin-right: 2.5rem;

        @include breakpoint(large) {
        //margin-left: 2.5rem;
        margin-right: auto;
        }
    }

    @include breakpoint(large) {

    }
*/
    h1.logo {
        width: rem-calc(280);
        margin: 0;
        font-size: rem-calc(15);
        display: none;
        transition: all .3s ease-out;
        //width: 60%;
        //max-width: 350px;

        @include breakpoint(large) {
            display: block;
        }


    }

    /*
    &.fixed {
        h1.logo {
            width: 170px;
            padding: 0;
        }
    }
    */

}

// Header fixed

header.fixthis {
    width: 100%;
    height: 90px;
    position: relative;
    z-index: 100;
    
    @include breakpoint(large) {
        height: 159px;
    }
    
    //transition: all .3s ease-out;
    
    * {
    //    transition: all .3s ease-out;
    }
    
    .header-inner {
        width: 100%;
        background: white;
    }
    
    &.is-fixed {
        
        .header-inner {
            position: fixed;
            bottom: 100%;
        }

        .top-bar {
            padding: 1rem 0 1rem 0;
        }

        h1.logo {
            width: rem-calc(180);
        }

    }
    
    
}


// Menu icon

.title-bar {
    justify-content: space-between !important;
    padding: 1rem .5rem;

    .logo {
        max-width: 150px;
    }
}
.ellipsis-icon {
    font-size: 2rem;
    color: $black;

    &:hover,
    :active {
        color: $primary-color;
    }
}

.hamburger-icon {
    display: inline-block;
    //float: right;
    width: 1.75rem;
    margin-top: 1rem;
    cursor: pointer;

    span {
        background: $dark-gray;
        display: block;
        height: 4px;
        border-radius: 2px;
        margin-bottom: .375rem;
    }
}

