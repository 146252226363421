/*
    NAVIGATION
*/

// Override settings
.title-bar,
.top-bar, .top-bar ul {
    background: transparent;
}

.title-bar {
    justify-content: center;
}

.top-bar ul ul {
    background: white
}
.is-dropdown-submenu {
    z-index: 100;
}
.title-bar {
    //height: 50px; // match logo height
    //justify-content: flex-end;
}
.menu-icon {
    height: auto;
    
    &::after {
        display: none;
    }
}

.no-js {
    @include breakpoint(small only) {
        .top-bar {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}
//




.main-menu {
    li {
        a {
            font-size: .875rem;
            text-transform: uppercase;
            color: $light-gray;

            &:hover,
            &.active {
                color: $black;
            }
        }
    }
}
